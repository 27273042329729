import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css/navigation";
import { Button, Container, Stack, useMediaQuery } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ".././App.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import DialogComp from "./DialogComp";

export default function DetailsWork({ data, title }) {
  const matches = useMediaQuery("(min-width:880px)");
  const match = useMediaQuery("(min-width:950px)");
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [workdata, setWorkData] = useState("");

  const handleReadMore = (selectedData) => {
    setOpen(true);
    setWorkData(selectedData);
  };
  return (
    <div
      style={{
        backgroundColor: "#FFF3EB",
        paddingTop: "40px",
        paddingBottom: "40px",
        position:'relative'
      }}
    >
      <Container sx={{ width: "88%" }}>
        <p style={{ color: "#1596D0", fontWeight: "bold", fontSize: "25px" }}>
          {t('How does connected')} {title} 
        </p>
      </Container>
      <Container>
        <Swiper
          modules={[Autoplay, Navigation]}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
          }}
          navigation={{
            prevEl: ".custom-swiper",
            nextEl: ".custom-swiper",
          }}
          loop
          slidesPerView={match ? 3 : 1}
          style={{ width: "100%", position: "relative", paddingBottom: "10px" }}
        >
          {data.map((d) => (
            <>
              <SwiperSlide
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Container
                  maxWidth="md"
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Card sx={{ width: "300px", p: "20px", height: "350px" }}>
                    <img
                      src={d.media}
                      style={{ width: "100px", borderRadius: "20px" }}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{ color: "#1490CA" }}
                      >
                        {d.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "black" }}
                        dangerouslySetInnerHTML={{
                          __html: d.description.substring(0, 300),
                        }}
                      ></Typography>
                      {d.description !== null && d.description.length > 299 && (
                        <Button
                          sx={{ bgcolor: "transparent", color: "#1490CA" }}
                          onClick={() =>
                            handleReadMore({
                              feedback: d.description,
                              co_name: d.title,
                              file:d.media
                            })
                          }
                        >
                          {t("Read More")}
                        </Button>
                      )}
                    </CardContent>
                  </Card>
                </Container>
              </SwiperSlide>
            </>
          ))}
        </Swiper>
      </Container>
      {matches && data.length > 1 && (
        <>
          <div className="custom-swiper">
            <NavigateBeforeIcon
              style={{
                color: "#1596D0",
                fontSize: "30px",
                position: "absolute",
                top: "50%",
                left: "10px",
                zIndex: "1",
                cursor: "pointer",
              }}
            />
          </div>
          <div className="custom-swiper">
            <NavigateNextIcon
              style={{
                color: "#1596D0",
                fontSize: "30px",
                position: "absolute",
                top: "50%",
                right: "10px",
                zIndex: "1",
                cursor: "pointer",
              }}
            />
          </div>
        </>
      )}
      <DialogComp open={open} setOpen={setOpen} data={workdata} />
    </div>
  );
}
