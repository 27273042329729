import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css/navigation";
import { Container, Stack, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function DetailsBenefits({ data, isImage, title }) {
  const matches = useMediaQuery("(min-width:760px)");
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");

  const swiperStyles = `
  @media (max-width: 850px) {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }

  .swiper-button-next, 
  .swiper-button-prev {
    color: #0074E8;
    top: 99%;
    transform: translateY(-50%);
    border:1px solid #0074E8;
    border-radius:100%;
    width:40px;
    heighr:40px
  }

  .swiper-button-next {
    right: 30%;
  }

  .swiper-button-prev {
    left: 60%;
  }

  .swiper-button-prev:after, .swiper-button-next:after {
    font-size: 10px;
  }
  `;

  return (
    <div style={{ backgroundColor: "#FFF3EB", paddingTop: '40px', marginTop: '50px', paddingBottom: '40px' }}>
      <Container sx={{ width: '60%' }}>
        <p style={{ color: "#1596D0", fontWeight: "bold", fontSize: "25px" }}>
          {t('Benefits of')} {title}
        </p>
      </Container>
      <style>{swiperStyles}</style>
      <Swiper
        modules={[Autoplay, Navigation]}
        autoplay={{
          delay: 8000,
          disableOnInteraction: false,
        }}
        navigation
        loop
        slidesPerView={1}
        style={{ width: "100%", position: "relative" }}
      >
        {data.map((d) => (
          <SwiperSlide
            key={d.title}
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Container maxWidth="lg">
              <Stack
                direction="row"
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: "20px",
                }}
                gap={12}
              >
                {isImage(d.media) ? (
                  <img
                    src={d.media}
                    style={{
                      width: matches ? "350px" : "250px",
                      objectFit: matches ? "cover" : "cover",
                      borderRadius: "20px",
                    }}
                  />
                ) : (
                  <video
                    src={d.media}
                    style={{
                      width: matches ? "350px" : "250px",
                      objectFit: matches ? "cover" : "cover",
                      borderRadius: "20px",
                      height: "300px",
                    }}
                    autoPlay={true}
                    loop={true}
                    controls={false}
                    playsInline
                    muted
                  />
                )}
                <Container maxWidth='sm'>
                  <p
                    style={{
                      color: "#1596D0",
                      fontWeight: "bold",
                      fontSize: "25px",
                    }}
                  >
                    {" "}
                    {d.title}
                  </p>
                  <p style={{ color: "black" }}
                                dangerouslySetInnerHTML={{ __html: d.description }}
                                ></p>
                </Container>
              </Stack>
            </Container>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
